import { Flex, Input, Form, Button, Card } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Role } from 'features/auth/types';
import { getPatientRouteUrl, PatientRoutes, Routes } from 'routes';
import { Link, useLocation } from 'react-router-dom';
import authSlice, {
  initialState,
  sendPasswordReset
} from 'features/auth/authSlice';
import { useReducer } from 'hooks/useReducer';
import { LinkTextComponent, ErrorMessage } from './page-sign-in';
import { styles } from './styles';
import { useState } from 'react';

export function ForgotPassword() {
  const location = useLocation<{ signInRole: Role }>(); // state passed through link
  const { signInRole } = location.state;
  const [{ error }, dispatch] = useReducer(authSlice, initialState); // user, isLoading,
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = (values: { email: string }) => {
    setIsLoading(true);
    dispatch(sendPasswordReset(values.email));
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <Card style={styles.container}>
      <Form
        onFinish={onFinish}
        name="forgot-password"
        className="login-form"
        style={styles.form}
        labelWrap={true}
      >
        <Flex style={styles.titleContainer} justify="center">
          <p style={styles.title}>Reset your password</p>
        </Flex>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email'
            },
            {
              type: 'email',
              message: 'Please enter a valid email'
            }
          ]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item>
          <Flex justify={'space-between'} align={'center'}>
            <div style={{ float: 'left', textAlign: 'center', height: '27px' }}>
              <span>Already have an account?</span>

              <Link
                to={{
                  pathname:
                    signInRole == Role.Patient
                      ? getPatientRouteUrl(PatientRoutes.PatientSignIn)
                      : Routes.ClinicianSignIn,
                  state: { signInRole: signInRole }
                }}
                component={LinkTextComponent}
              >
                Login
              </Link>
            </div>
            {error && <ErrorMessage errorMessage={error} />}
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '65px', float: 'right', height: '27px' }}
              loading={isLoading}
            >
              Reset
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Card>
  );
}
