import { Moment } from 'moment';

/**
 * Entity collection names in firestore
 *
 * Add in alphabetical order, please
 */
export enum Collection {
  Clinics = 'clinics',
  Clinicians = 'clinicians',
  Patients = 'patients',
  PatientAssessments = 'patientAssessments',
  PatientInjuries = 'patientInjuries',
  PatientPersonalHistory = 'patientPersonalHistories',
  PendingPatientAssessments = 'pendingPatientAssessments',
  PatientProfiles = 'patientProfiles',
  TreatmentGoals = 'treatmentGoals'
}

/* Add in alphabetical order, please */
export enum PatientSubcollection {
  ClinicianWorksheetRatings = 'clinicianWorksheetRatings',
  ClinicianWorksheetScores = 'clinicianWorksheetScores',
  ClinicianWorksheets = 'clinicianWorksheets',
  Goals = 'goals',
  HowAreYou = 'howAreYou',
  ImpactApi = 'impactAPI',
  Problems = 'problems',
  QualityOfLife = 'qualityOfLife',
  Scores = 'scores',
  SectionAnswers = 'sectionAnswers',
  SubSymptoms = 'subSymptoms',
  SymptomCalcInfo = 'symptomCalcInfo',
  Symptoms = 'symptoms',
  InventoriesDhi = 'inventoriesDhi',
  InventoriesPcs = 'inventoriesPcs',
  InventoriesPromis = 'inventoriesPromis',
  InventoriesCgs = 'inventoriesCgs',
  InventoriesImpact = 'inventoriesImpact',
  InventoriesConcussionTreatmentRecommendations = 'inventoriesConcussionTreatmentRecommendations',
  Onboardings = 'onboardings',
  ConcussionClassifications = 'concussionClassifications',
  SubtypeRangeResults = 'subtypeRangeResults'
}

// export enum ApiSubCollection {
//   Impact = "impact"
// }

export interface DocumentMetadata {
  schemaVersion: string;
  created: Moment;
  updated: Moment;
}

export interface Document {
  id?: string;
  metadata?: DocumentMetadata;
}
