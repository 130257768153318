import { Button, ButtonProps, ConfigProvider } from 'antd';
import clsx from 'clsx';

export const darkButtonStyles = {
  padding: '1.2rem 2rem',
  borderColor: 'none',
  borderRadius: '6rem',
  background: '#242425',
  color: 'white',
  boxShadow: 'none'
} as const;
export function DarkButton(props: ButtonProps) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: '#242425',
            defaultColor: 'white'
          }
        }
      }}
    >
      <Button
        {...props}
        className={clsx('crx-typography')}
        style={{ ...darkButtonStyles, ...props.style }}
      />
    </ConfigProvider>
  );
}
