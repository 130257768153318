import { Alert, Flex } from 'antd';
import clsx from 'clsx';
import { Title } from 'components/mvp-typography';
import React, { forwardRef, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { resolveError } from 'utils/formatters/error/resolve-error';

export interface Page2Props {
  title: string;
  hideTitle?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  children: React.PropsWithChildren<any>['children'];
  className?: string | string[];
  extra?: ReactNode;
}

/**
 * Expose the DOM reference of the root element
 *
 * This is useful when the entire page should be printed without the sidebar
 */
export const PrintableRootPage = forwardRef<any, Page2Props>(function Page2(
  props: Page2Props,
  ref
) {
  return (
    <div className={clsx('page', props.className)} ref={ref ?? undefined}>
      <Flex justify={'space-between'}>
        <div>
          {!props.hideTitle && (
            <Title style={{ color: '#230056', fontWeight: 'bold' }}>
              {props.title}
            </Title>
          )}
        </div>
        <div>{props.extra}</div>
      </Flex>
      <ErrorBoundary
        fallbackRender={props => {
          const error = resolveError(props.error);
          console.error('Caught unhandled error', error);
          return (
            <Alert
              message={'Unrecoverable error'}
              description={[error.message, error.stack].join('\n')}
              type="error"
              showIcon
            />
          );
        }}
      >
        {!props.hasError && !props.isLoading && props.children}
      </ErrorBoundary>
      {props.hasError && (
        <Alert
          message="Error"
          description="OOPS! Something went wrong. Please try again later."
          type="error"
          showIcon
        />
      )}
    </div>
  );
});
