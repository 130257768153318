import { Alert, Flex, Switch, Table, Tag } from 'antd';
import { invokeCalculatePatientSubtype } from 'api/firebase/firebase-api';
import { DarkButton } from 'components/dark-button';
import { Text } from 'components/mvp-typography';
import { PrintableRootPage } from 'components/page/page-2';
import { createSubtypeRangeRepo } from 'components/patient-info/patientInfoSlice';
import { Collection } from 'documents/document';
import { PatientSubtypeRangesResult } from 'documents/patient-assessment/patient-subtype-range';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { formatNumber } from 'utils/formatters/normalise';
import { ErrorDisplay } from '../assessment/questionnaire/ErrorDisplay';

export function PatientSubtypeRange() {
  const { patientId } = useParams<{ patientId: string }>();
  const [error, setError] = useState<Error | null>();
  const [data, setData] = useState<
    | { isLoading: true }
    | {
        isLoading: false;
        data:
          | ({
              attributeName: string;
            } & PatientSubtypeRangesResult['ranges'][0])[]
          | null;
      }
  >({ isLoading: true });
  const [useFormatter, setUseFormatter] = useState<boolean>(true);
  const _formatNumber = useCallback(
    (value: number) => {
      if (useFormatter) return formatNumber(value);
      return value;
    },
    [useFormatter]
  );
  const setup = useCallback(async (patientId: string) => {
    setData({ isLoading: true });
    const repo = createSubtypeRangeRepo(patientId);
    const list = await repo
      .getList({ orderBy: ['date', 'desc'] })
      .catch(() => []);
    if (list.length === 0) {
      setData({
        isLoading: false,
        data: null
      });
    } else {
      const docData = list[0].data.ranges;
      console.log(docData);
      setData({
        isLoading: false,
        data: Object.keys(docData)
          .map(attributeName => ({
            ...docData[attributeName],
            attributeName
          }))
          .sort((a, b) => a.attributeName.localeCompare(b.attributeName))
      });
    }

    setError(null);
  }, []);
  useEffect(() => {
    setup(patientId).catch(e => {
      const error = resolveError(e);
      setError(error);
    });
  }, [patientId, setup]);

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        title={'Something went wrong'}
        description={
          'Please try again in a few minutes. If the error persists please contact your clinic admin'
        }
      />
    );
  }

  return (
    <PrintableRootPage title="Subtype Range Algorithm">
      {!data.isLoading && data.data === null && (
        <Alert
          description={
            <DarkButton
              onClick={async () => {
                setData({ isLoading: true });
                try {
                  await invokeCalculatePatientSubtype({
                    patientId,
                    collection: Collection.PatientAssessments,
                    destId: Date.now().toString()
                  });
                  await setup(patientId);
                } catch (e) {
                  setData({ isLoading: false, data: null });
                }
              }}
            >
              Generate subtype range
            </DarkButton>
          }
          message={[
            `This patient does not have a subtype range generated.`,
            `This can happen if they are an old user and have not recently completed an assessment.`,
            `Click the button below to generate the ranges.`
          ].join(' ')}
        />
      )}
      <Flex gap={'large'}>
        <Text>Show unformatted values</Text>
        <Switch
          onClick={() => {
            setUseFormatter(old => !old);
          }}
        />
      </Flex>
      <Table
        pagination={{
          pageSize: 14
        }}
        dataSource={!data.isLoading && data.data !== null ? data.data : []}
        loading={data.isLoading}
        sortDirections={['descend', 'ascend']}
        columns={[
          {
            title: 'Name',
            dataIndex: 'attributeName',
            sorter: (a, b) => a.attributeName.localeCompare(b.attributeName)
          },
          {
            title: 'Patient Score',
            dataIndex: 'attributeValue',
            render: value => `${_formatNumber(value)}`,
            sorter: (a, b) => a.attributeValue - b.attributeValue
          },
          {
            title: 'Subtype Mean Score',
            dataIndex: 'meanValue',
            render: value => `${_formatNumber(value)}`,
            sorter: (a, b) => a.meanValue - b.meanValue
          },

          {
            title: 'Standard Deviation',
            dataIndex: 'stdDeviation',
            render: value => `${_formatNumber(value)}`,
            sorter: (a, b) => a.stdDeviation - b.stdDeviation
          },
          {
            title: 'Is in expected range',
            dataIndex: 'isInSTDRange',
            render: value =>
              value ? (
                <Tag color={'green'}>Yes</Tag>
              ) : (
                <Tag color={'red'}>No</Tag>
              ),
            sorter: (a, b) => Number(a.isInSTDRange) - Number(b.isInSTDRange),
            defaultSortOrder: 'descend'
          },
          {
            title: 'Subtype percentile',
            render(_value, record) {
              if (!record.isInSTDRange) return '';
              return `${_formatNumber(
                stdDeviationPercentile(
                  record.attributeValue,
                  record.meanValue,
                  record.stdDeviation
                ) * 100
              )}%`;
            },
            sorter: (a, b) => {
              return (
                stdDeviationPercentile(
                  a.attributeValue,
                  a.meanValue,
                  a.stdDeviation
                ) -
                stdDeviationPercentile(
                  b.attributeValue,
                  b.meanValue,
                  b.stdDeviation
                )
              );
            }
          },
          {
            title: 'Difference from mean score',
            render(_value, record) {
              if (record.isInSTDRange) return '';
              return `${_formatNumber(
                percentDifference(record.meanValue, record.attributeValue)
              )}%`;
            },
            sorter: (a, b) =>
              percentDifference(a.meanValue, a.attributeValue) -
              percentDifference(b.meanValue, b.attributeValue)
          }
        ]}
      />
    </PrintableRootPage>
  );
}

const percentDifference = (a: number, b: number) =>
  (Math.abs(a - b) / ((a + b) / 2)) * 100;

const stdDeviationPercentile = (value: number, mean: number, stdDev: number) =>
  Math.abs(value - mean) / stdDev;
