import { useEffect, useMemo, useRef } from 'react';

import { Alert, Col, Row, Skeleton, Typography } from 'antd';

import { DarkButton } from 'components/dark-button';
import { PrintableRootPage } from 'components/page/page-2';
import { generateEnhancedTreatmentRecommendations } from 'components/patient-info/actions/patientInfoSlice-actions-generateEnhancedTreatmentRecommendations';
import {
  initialState,
  patientInfoInitialLoad,
  PatientInfoSliceReducer
} from 'components/patient-info/patientInfoSlice';
import { PrescriptionPadExportContainer } from 'features/prescription-pad/prescription-pad';
import { useReducer } from 'hooks/useReducer';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { halfCol } from 'utils/antd';
import { ConcussionRxPrintContext } from 'utils/print';
import { ExportAsPDFButton } from '../../../components/export-as-pdf-button';
import { formatTreatmentRecommendations } from './formatTreatmentRecommendations';
import { TreatmentCard } from './treatment-card';
import './TreatmentRecommendations.scss';
import { ErrorDisplay } from '../assessment/questionnaire/ErrorDisplay';
export const severityMap = ['no', 'mild', 'moderate', 'severe'];

export enum TreatmentSpecialists {
  PHYSIO = 'Physiotherapy',
  COUNSEL = 'Counseling',
  NEURO = 'Neuropsychology',
  OCCUPATIONAL = 'Occupational therapy'
}

export type Content = { name: string; link: string };

export function TreatmentRecommendationsV2() {
  const [patientInfo, patientInfoDispatch] = useReducer(
    PatientInfoSliceReducer,
    initialState
  );
  const { patientId } = useParams<{ patientId: string }>();
  const prescriptionPadRef = useRef<any | null>(null);

  useEffect(() => {
    patientInfoDispatch(patientInfoInitialLoad(patientId));
  }, [patientId]);

  const RECOMMENDATIONS = useMemo(() => {
    if (patientInfo.concussionTreatmentRecommendations.length === 0) return [];
    return formatTreatmentRecommendations(
      patientInfo.concussionTreatmentRecommendations[0]
    );
  }, [patientInfo]);
  if (patientInfo.hasError) {
    return <ErrorDisplay description="" error={patientInfo.error!} title="" />;
  }

  if (patientInfo.isLoading) {
    return <Skeleton active />;
  }

  return (
    <ConcussionRxPrintContext
      content={() => prescriptionPadRef.current}
      documentTitle={`prescription-${moment().toISOString()}`}
      removeAfterPrint
    >
      <PrintableRootPage
        title={`${patientInfo.patient.displayName} Recommended Treatment`}
        extra={[<ExportAsPDFButton key="1" />]}
      >
        <div
          style={{
            borderRadius: '16px',
            backgroundColor: 'white',
            padding: '30px 40px 30px 40px'
          }}
        >
          <div style={{ marginBottom: '40px' }}>
            <h3 style={{ marginBottom: '16px' }}>
              ConcussionRx Recommendations
            </h3>
            <p>
              The following reflects evidence-based best practice in concussion
              management and builds on the principles outlined in the most
              recent consensus statements. This tool is intended to help inform
              treatment planning and provide direction to support your patient
              achieving a complete recovery.
            </p>
          </div>
          {patientInfo.patient &&
            patientInfo.concussionTreatmentRecommendations.length === 0 && (
              <Alert
                description={
                  <DarkButton
                    loading={patientInfo.isLoading}
                    onClick={async () => {
                      if (patientInfo.patient.id === undefined)
                        throw new Error(
                          '[Button - Generate treatment recommendations] Patient ID is not set'
                        );
                      patientInfoDispatch(
                        generateEnhancedTreatmentRecommendations()
                      );
                    }}
                  >
                    Generate treatment recommendations
                  </DarkButton>
                }
                message={[
                  `This patient does not have treatment recommendations generated.`,
                  `This can happen if they are an old user and have not recently completed an assessment.`,
                  `Click the button below to generate new recommendations.`
                ].join(' ')}
              />
            )}
          <Row
            /* Recommendations display */
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ marginBottom: '24px' }}
          >
            {RECOMMENDATIONS.map((content, index) => (
              <Col {...halfCol} key={`recommended-treatment-${index}`}>
                <TreatmentCard title={content.title} img={content.img}>
                  <ul style={{ listStyleType: 'none', padding: '0' }}>
                    {content.content.length > 0 &&
                      content.content.map((content, index) => (
                        <li
                          style={{ paddingBottom: '8px' }}
                          key={`rxphysio` + index}
                        >
                          <span className="treatment_content" rel="noreferrer">
                            {content.name}
                          </span>
                        </li>
                      ))}
                  </ul>
                </TreatmentCard>
              </Col>
            ))}
          </Row>
          <Row
          /* Print button */
          >
            <Col style={{ fontFamily: 'Comfortaa' }}>
              <div style={{ margin: '40px 0px' }}>
                <Typography.Title level={2}>What&apos;s next?</Typography.Title>
                <p>
                  Have {patientInfo.patient.displayName} repeat their
                  ConcussionRx evaluation every 30 days to track their progress
                  and refine treatment recommendations as concussion recovery
                  progresses.
                </p>
              </div>

              <ExportAsPDFButton />
            </Col>
          </Row>
        </div>

        <div
          /* Print container */
          style={{ display: 'none' }}
        >
          <PrescriptionPadExportContainer
            getRef={ref => (prescriptionPadRef.current = ref)}
            patientInfo={patientInfo}
          />
        </div>
      </PrintableRootPage>
    </ConcussionRxPrintContext>
  );
}
